import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Paragraphs = ({pcontent}) => {
    return (
        <div>
            {
                pcontent.map((el, index) => (
                    <p key={index}>{el}</p>
                )
                )
            }
        </div>
    )
}

export default function PrivacyPolicy() {
    const pageTitle = "Privacy Policy"
    const pageDescription = "Privacy Policy. Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide."
    const content = {
            title: "Privacy Policy",
            updated: "Dec 04 2020",
            content: [
                {
                    title: "Who we are",
                    paragraphs: [
                        "Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide. Our staff has over 60 combined years of experience in DNA, molecular biology, forensics, paternity, human identification and related scientific fields."
                    ]
                },
                {
                    title: "What personal data we collect and why we collect it",
                    paragraphs: [
                        "Personal information is only collected when a form submission occurs. Depending on the form, your email, name and/or shipping/billing address might be collected for contact and shipping purposes only. Independent Forensics cares about your privacy, and we make sure your personal information is secured. This is why we use trusted and tested services such as Formspree, Shopify, tawk.to and Yotpo to collect your information."
                    ]
                },
                {
                    title: "Reviews",
                    paragraphs: [
                        "In some product pages, you might have an option to leave a review. This is an optional feature that requires a name and email for verification."
                    ]
                },
                {
                    title: "Cookies",
                    paragraphs: [
                        "We currently do not use any cookies. However, we might use embedded content from other websites that might collect cookies such as YouTube and Facebook."
                    ]
                }, 
                {
                    title: "Embedded content from other websites",
                    paragraphs: [
                        "Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.",
                        "These websites, such as YouTube and Facebook, may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.",
                        "If this is a concern, we strongly recommend using the latest Firefox browser which offers enhanced tracking protection and automatically blocks tracking scripts."
                    ]
                },
                {
                    title: "Analytics",
                    paragraphs: [
                        "We currently do not use any analytics such as Google analytics or Facebook Pixel in this website."
                    ]
                },
            ]
        }

    return (
        <Layout>
            <SEO title={pageTitle} description={pageDescription} />
            <Header headerText={content.title} />
            <p>{content.updated}</p>
            {
                content.content.map((el, index) => (
                    <div key={index}>
                        <h2>{el.title}</h2>
                        <Paragraphs pcontent={el.paragraphs} />
                    </div>
                ))
            }
        </Layout>
    )
}